<template>
  <div>
    <div
      v-if="
        typeof error_messages === 'object' &&
          Object.keys(error_messages).length > 0
      "
      class="alert alert-danger"
    >
      <ul>
        <template v-for="(error, i) in error_messages">
          <span v-bind:key="i">
            <li v-for="(message, ii) in error" v-bind:key="ii">
              {{ message }}
            </li>
          </span>
        </template>
      </ul>
    </div>
    <div class="tabs">
      <v-tabs
        v-model="tab"
        background-color="light primary"
        class="elevation-2"
        dark
      >
        <v-tab>{{ $t("COMPONENTS.MATERIAL") }}</v-tab>
        <v-tab v-if="isEdit">Изисквания към материалите</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat class="my-card-fw">
            <v-card-text>
              <vue-form-generator
                ref="form"
                :schema="materialSchema"
                :model="material"
                :options="formOptions"
              ></vue-form-generator>
              <br />
              <v-btn
                @click="save()"
                class="btn btn-success btn-elevate kt-login__btn-primary text-white"
                >{{ $t("BUTTON.SAVE") }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item v-if="isEdit" class="pt-2">
          <v-btn
            @click="addDetail()"
            class="btn btn-success btn-elevate kt-login__btn-primary text-white"
            >{{ $t("BUTTON.ADD_REQUIREMENT") }}
          </v-btn>

          <div v-if="addNew">
            <v-row class="mx-2">
              <v-col cols="5">
                Вид на изискване:
                <vu-select
                  append-to-body
                  :options="materialProperties"
                  label="name"
                  v-model="property"
                  @input="property_values = []"
                ></vu-select>
              </v-col>
              <v-col cols="5">
                Стойности:
                <vu-select
                  append-to-body
                  v-if="property != null"
                  :options="property.values"
                  label="name"
                  v-model="property_values"
                ></vu-select>
              </v-col>

              <v-col cols="2">
                <br />
                <v-btn
                  @click="saveDetail()"
                  class="btn btn-success btn-block btn-elevate kt-login__btn-primary text-white"
                  >{{ $t("BUTTON.ADD") }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <hr />

          <template v-if="isLoadingMaterials == false">
            <v-row
              class="mx-2 border-bottom"
              v-for="detail of material.details"
              v-bind:key="detail.id"
            >
              <v-col cols="5">
                Изискване:
                <input
                  type="text"
                  class="form-control"
                  :value="
                    detail.propertyName +
                      (detail.isArchived ? ' (Архивирано)' : '')
                  "
                  disabled
                />
              </v-col>
              <v-col cols="5">
                Стойност:
                <input
                  type="text"
                  class="form-control"
                  :value="
                    detail.valueName +
                      (detail.isArchived ? ' (Архивирано)' : '')
                  "
                  disabled
                />
              </v-col>

              <v-col cols="2">
                <br />
                <v-btn
                  @click="deleteProperty(detail)"
                  class="btn btn-danger btn-block btn-elevate kt-login__btn-primary text-white"
                  >Изтрий</v-btn
                >
              </v-col>
            </v-row>
            <br />
            <v-btn
              @click="save()"
              class="btn btn-success btn-elevate kt-login__btn-primary text-white"
              >{{ $t("BUTTON.SAVE") }}
            </v-btn>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

import VueFormGenerator from "vue-form-generator";
import "vue-form-generator/dist/vfg.css"; // optional full css additions

import {
  GET_MATERIAL,
  SAVE_MATERIAL,
  UPDATE_MATERIAL
} from "@/store/materials.module";

import { GET_DATA } from "@/store/formselect.module";

import { GET_MATERIALPROPERTIES } from "@/store/materialProperties.module";

import { GET_CLIENTS } from "@/store/clients.module";

import { mapGetters, mapState } from "vuex";
// Vue.use(VueFormGenerator)

import _ from "lodash";

export default {
  name: "createUpdate",

  components: {
    "vue-form-generator": VueFormGenerator.component
  },
  computed: {
    ...mapGetters(["isLoadingMaterials"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    isEdit: function() {
      if (typeof this.$route.params.id !== "undefined") {
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      error_messages: [],
      addNew: false,
      model: "materials",
      tab: 0,
      material: {
        name: null,
        details: []
      },
      materialTypes: {},
      materialProperties: [],
      materialSchema: {
        fields: [
          {
            type: "input",
            inputType: "text",
            label: "Име",
            model: "name",
            required: true,
            validator: VueFormGenerator.validators.string.locale({
              fieldIsRequired: "Полето е задължително !!!",
              textTooSmall: "Полето трябва да бъде минимум {0} знака !!!"
            })
          }
        ]
      },
      requirementSchema: {
        fields: [{}]
      },
      requirements: {},
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: false,
        validateAsync: true
      },
      property: {
        values: []
      },
      property_values: []
    };
  },
  mounted() {
    let vm = this;

    _.delay(function() {
      var current_page = "Добави";
      if (typeof vm.$route.params.id !== "undefined") {
        current_page = "Промени";
      }
      vm.$store.dispatch(SET_BREADCRUMB, [
        { title: "Материали", route: "/materials" },
        { title: current_page }
      ]);
    }, 300);
    this.getMaterialTypes();
    this.getMaterialProperties();
  },
  methods: {
    deleteProperty: function(detail) {
      let vm = this;
      vm.$confirm({
        title: "Сигурен ли си, че искаш да изтриеш този запис?",
        content: "Tова дейтсвие не може да бъде отменено!",
        icon: "warning",
        okText: "Потвърди",
        cancelText: "Откажи",
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        onOk() {
          vm.material.details = _.remove(vm.material.details, function(n) {
            return n !== detail;
          });
        },
        onCancel() {}
      });
    },
    setPropertyValues: function(detail) {
      let options = _.find(this.materialProperties, { id: detail.propertyId });
      if (typeof options !== "undefined") {
        return options.values;
      }

      return [];
    },
    saveDetail: function() {
      let vm = this;

      if (!vm.property.id || !vm.property_values.id) {
        return;
      }

      vm.material.details.push({
        propertyName: vm.property.name,
        propertyId: vm.property.id,
        valueId: vm.property_values.id,
        valueName: vm.property_values.name
      });

      vm.addNew = false;
      vm.property_values = [];
      vm.property = {
        values: []
      };
    },
    getMaterialProperties: function() {
      let vm = this;
      this.error_messages = [];
      this.$store
        .dispatch(GET_MATERIALPROPERTIES)
        .then(data => {
          vm.$nextTick(function() {
            vm.materialProperties = data.data;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    addDetail: function() {
      let vm = this;

      vm.addNew = true;
      this.error_messages = [];
      this.$store
        .dispatch(GET_MATERIALPROPERTIES)
        .then(data => {
          vm.$nextTick(function() {
            vm.materialProperties = data.data;

            let addMaterialDetail = {
              type: "select",
              label: "Изискване",
              model: "property",
              selectOptions: { noneSelectedText: "избери" },
              values: vm.materialProperties,
              styleClasses: "col-md-6",
              onChanged: function(model, newVal) {
                vm.addRequirement(newVal);
              }
            };

            vm.requirementSchema.fields.push(addMaterialDetail);
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    getMaterialTypes: function() {
      let vm = this;

      let payload = {
        materialTypes: {
          fields: ["id", "name"],
          search: ""
        }
      };
      this.error_messages = [];
      this.$store
        .dispatch(GET_DATA, payload)
        .then(data => {
          vm.$nextTick(function() {
            vm.materialTypes = data.data.materialTypes;

            let addTypeId = {
              type: "select",
              label: "Вид на материала",
              model: "typeId",
              required: true,
              selectOptions: { noneSelectedText: "избери" },
              hideNoneSelectedText: true,
              values: vm.materialTypes,
              validator: VueFormGenerator.validators.required.locale({
                fieldIsRequired: "Полето е задължително !!!"
              })
            };

            vm.materialSchema.fields.push(addTypeId);

            if (typeof this.$route.params.id !== "undefined") {
              vm.getData().then(() => {
                vm.getClients();
              });
            } else {
              vm.getClients();
            }
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    async save() {
      let vm = this;

      vm.error_messages = [];

      let errors = await this.$refs.form.validate();

      if (errors.length) {
        return;
      }

      var action =
        typeof this.$route.params.id !== "undefined"
          ? UPDATE_MATERIAL
          : SAVE_MATERIAL;

      vm.$store
        .dispatch(action, { payload: vm.material })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.$router.push({ name: "materialsList" });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "materialsList" });
          }
        });
    },
    getData: function() {
      let vm = this;

      let id = this.$route.params.id;
      this.error_messages = [];
      return this.$store
        .dispatch(GET_MATERIAL, id)
        .then(data => {
          vm.$nextTick(function() {
            vm.material = { ...data.data };
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!"
            });
            vm.$router.push({ name: "materialsList" });
          }
        });
    },
    getClients: function() {
      let vm = this;

      // let id = this.$route.params.id;
      this.error_messages = [];
      this.$store
        .dispatch(GET_CLIENTS)
        .then(data => {
          vm.$nextTick(function() {
            vm.clients = data.data;

            if (
              typeof this.$route.params.id !== "undefined" &&
              vm.material.clientIsArchived
            ) {
              vm.clients.push({
                id: vm.material.clientId,
                name: vm.material.clientName + " (Архивиран)"
              });

              vm.clients = _.uniqBy(vm.clients, "id");
            }

            let clientId = {
              type: "select",
              inputType: "text",
              label: "Клиент",
              model: "clientId",
              selectOptions: { noneSelectedText: "избери" },
              values: vm.clients,
              required: true,
              validator: VueFormGenerator.validators.required.locale({
                fieldIsRequired: "Полето е задължително !!!"
              })
            };

            let note = {
              type: "textArea",
              label: "Забележка",
              model: "note"
            };

            vm.materialSchema.fields.push(clientId);
            vm.materialSchema.fields.push(note);
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    }
  }
};
</script>
